// src/ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface ProtectedRouteProps {
  children: JSX.Element;
  requiredPermissions: string[]; // Array of required permissions
}

const ProtectedRoute = ({ children, requiredPermissions }: ProtectedRouteProps) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (user) {
      const userPermissions = user.permset;
      if (
        userPermissions.includes('admin') ||
        requiredPermissions.every((perm) => userPermissions.includes(perm))
      ) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    }
  }, [user, requiredPermissions]);

  if (!isAuthenticated) {
    return <Navigate to="/loginform" state={{ from: location }} replace />;
  }

  if (!hasPermission) {
    return <div>You do not have permission to view this page.</div>;
  }

  return children;
};

export default ProtectedRoute;
