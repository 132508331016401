import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

interface DecodedToken {
  id: number;
  username: string;
  permset: string[];
  exp: number;
}

interface User {
  username: string;
  permset: string[];
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  checkAuthStatus: () => Promise<void>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        //const decodedToken: DecodedToken = (jwt_decode as (token: string) => DecodedToken)(token);
        const decodedToken: DecodedToken = jwt_decode(token); // Should work now


        if (decodedToken.exp * 1000 < Date.now()) {
          logout();
          return;
        }

        setUser({
          username: decodedToken.username,
          permset: decodedToken.permset,
        });
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Token verification failed:', error);
        logout();
      }
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post('/login', { username, password });
      const token = response.data.token;
      localStorage.setItem('authToken', token);

      const decodedToken: DecodedToken = jwt_decode(token);


      setUser({
        username: decodedToken.username,
        permset: decodedToken.permset,
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, checkAuthStatus, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
