import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Environment, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { EXRLoader } from 'three-stdlib';
import { useLoader } from '@react-three/fiber';
import Trex from '../components/dinoclasses/Trex.tsx'; // Import the Trex component



const DinoPen: React.FC = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const velociraptorRef = useRef<THREE.Group>(null!);
  return (
    <Canvas
      camera={{ position: [10, 10, 10], fov: 50 }}
      style={{ width: '100%', height: '100%' }}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <CameraController />
      <ambientLight intensity={0.5} />
      <directionalLight position={[0, 10, 5]} intensity={1} />
      <Ground />
      <VerticalRectangle />
      <VerticalRectangle2 />
      <VerticalRectangle3 />
      <Dinosaur isMouseOver={isMouseOver} ref={velociraptorRef} />
      <Trex /> {/* Removed velociraptorRef prop */}
      <Indoraptor />
      <Environment preset="sunset" />
    </Canvas>
  );
};

const CameraController: React.FC = () => {
    const { camera, gl } = useThree();
  
    // State variables for camera control
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [lastMousePosition, setLastMousePosition] = useState<{ x: number; y: number } | null>(null);
  
    // Spherical coordinates
    const [theta, setTheta] = useState(0); // Horizontal angle
    const [t, setT] = useState(0.5); // Vertical path parameter (0 = top, 1 = bottom)
    const [radiusMultiplier, setRadiusMultiplier] = useState(1); // Control path extension/retraction
  
    // Global offsets for moving the entire path along x and z axes
    const [xOffset, setXOffset] = useState(0); // Global x-axis offset
    const [zOffset, setZOffset] = useState(0); // Global z-axis offset
  
    // Movement speed for forward/backward along x and z
    const moveSpeed = 0.5;
  
    // Update camera position based on spherical coordinates and global offsets
    useEffect(() => {
      // Define the ranges for phi and radius
      const phiMax = 0.1; // Looking almost directly down
      const phiMin = Math.PI / 2 - 0.1; // Looking forward
  
      // Define base radius values
      const baseRadiusMax = 30; // At the top of the path
      const baseRadiusMin = 5;  // At the bottom of the path (remains fixed)
  
      // Scale the maximum radius using the radiusMultiplier
      const radiusMax = baseRadiusMax * radiusMultiplier;
      const radiusMin = baseRadiusMin; // Keep the minimum radius fixed
  
      // Interpolate phi and radius based on t (vertical path)
      const phi = phiMax + t * (phiMin - phiMax);
      const radius = radiusMax + t * (radiusMin - radiusMax); // Radius depends on t
  
      // Convert spherical coordinates to Cartesian coordinates (without global offset)
      const x = radius * Math.sin(phi) * Math.sin(theta);
      const y = radius * Math.cos(phi);
      const z = radius * Math.sin(phi) * Math.cos(theta);
  
      // Apply global offsets to x and z
      const xPos = x + xOffset;
      const zPos = z + zOffset;
  
      // Set camera position
      camera.position.set(xPos, y, zPos);
      camera.lookAt(xOffset, 0, zOffset); // Look at the translated center of the scene
    }, [camera, theta, t, radiusMultiplier, xOffset, zOffset]);
  
    useEffect(() => {
      // Mouse event handlers
      const handleMouseDown = (event: MouseEvent) => {
        setIsMouseDown(true);
        setLastMousePosition({ x: event.clientX, y: event.clientY });
      };
  
      const handleMouseUp = () => {
        setIsMouseDown(false);
        setLastMousePosition(null);
      };
  
      const handleMouseMove = (event: MouseEvent) => {
        if (!isMouseDown || !lastMousePosition) return;
  
        const deltaX = event.clientX - lastMousePosition.x;
        const rotationSpeed = 0.005; // Adjust rotation sensitivity
        setTheta((prevTheta) => prevTheta - deltaX * rotationSpeed);
  
        // Update last mouse position
        setLastMousePosition({ x: event.clientX, y: event.clientY });
      };
  
      // Key event handlers
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === '5') {
          // Move camera up along the spherical path
          setT((prevT) => Math.max(0, prevT - 0.05)); // Decrease t to move up the path
        } else if (event.key === '6') {
          // Move camera down along the spherical path
          setT((prevT) => Math.min(1, prevT + 0.05)); // Increase t to move down the path
        } else if (event.key === '1') {
          // Increase the radiusMultiplier to extend the path outward
          setRadiusMultiplier((prev) => Math.min(2, prev + 0.1)); // Increase multiplier (max limit of 2x)
        } else if (event.key === '2') {
          // Decrease the radiusMultiplier to retract the path inward
          setRadiusMultiplier((prev) => Math.max(1, prev - 0.1)); // Decrease multiplier (min limit of 1x)
        } else if (event.key === '3') {
          // Move the entire path forward (along the x and z axes)
          const direction = new THREE.Vector3();
          camera.getWorldDirection(direction); // Get the forward direction the camera is facing
          direction.y = 0; // Keep movement horizontal (x and z only)
          direction.normalize();
          setXOffset((prevX) => prevX + direction.x * moveSpeed);
          setZOffset((prevZ) => prevZ + direction.z * moveSpeed);
        } else if (event.key === '4') {
          // Move the entire path backward (along the x and z axes)
          const direction = new THREE.Vector3();
          camera.getWorldDirection(direction); // Get the forward direction the camera is facing
          direction.y = 0; // Keep movement horizontal (x and z only)
          direction.normalize();
          setXOffset((prevX) => prevX - direction.x * moveSpeed);
          setZOffset((prevZ) => prevZ - direction.z * moveSpeed);
        }
      };
  
      // Add event listeners
      gl.domElement.addEventListener('mousedown', handleMouseDown);
      gl.domElement.addEventListener('mouseup', handleMouseUp);
      gl.domElement.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('keydown', handleKeyDown);
  
      // Clean up
      return () => {
        gl.domElement.removeEventListener('mousedown', handleMouseDown);
        gl.domElement.removeEventListener('mouseup', handleMouseUp);
        gl.domElement.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [camera, gl.domElement, isMouseDown, lastMousePosition]);
  
    return null;
  };
  
  
  
const VerticalRectangle: React.FC = () => {
    return (
      <mesh position={[0, 0.9, 0]} name="VerticalRectangle">  {/* Centered at 0 and half height  */}
        <planeGeometry args={[1, 1.8]} /> {/* Width = 1m, Height = 1.8m */}
        <meshStandardMaterial color="blue" /> {/* Simple blue material */}
      </mesh>
    );
  };
  
  const VerticalRectangle2: React.FC = () => {
    return (
      <mesh position={[-2, 2.9, 0]} name="VerticalRectangle">  {/* Centered at 0 and half height  */}
        <planeGeometry args={[1, 5.8]} /> {/* Width = 1m, Height = 1.8m */}
        <meshStandardMaterial color="green" /> {/* Simple blue material */}
      </mesh>
    );
  };

  const VerticalRectangle3: React.FC = () => {
    return (
      <mesh position={[-4, 1.25, 0]} name="VerticalRectangle">  {/* Centered at 0 and half height  */}
        <planeGeometry args={[1, 2.5]} /> {/* Width = 1m, Height = 1.8m */}
        <meshStandardMaterial color="red" /> {/* Simple blue material */}
      </mesh>
    );
  };

const Ground: React.FC = () => {
  // Load the textures
  //const diffuseMap = useLoader(THREE.TextureLoader, '/textures/sparse_grass_diff_4k.jpg');
  const diffuseMap = useLoader(THREE.TextureLoader, '/textures/rockydiff.jpg');
  //const displacementMap = useLoader(THREE.TextureLoader, '/textures/sparse_grass_disp_4k.png'); // Load .exr file
  const displacementMap = useLoader(THREE.TextureLoader, '/textures/rockydisp.png'); // Load .exr file
  //const normalMap = useLoader(EXRLoader, '/textures/sparse_grass_nor_gl_4k.exr'); // Load .exr file
  const normalMap = useLoader(EXRLoader, '/textures/rockynor.exr'); // Load .exr file
  //const roughnessMap = useLoader(THREE.TextureLoader, '/textures/sparse_grass_rough_4k.jpg');
  const roughnessMap = useLoader(EXRLoader, '/textures/rockyrough.exr');


  // Configure texture wrapping and repeating if needed
  diffuseMap.wrapS = diffuseMap.wrapT = THREE.RepeatWrapping;
  normalMap.wrapS = normalMap.wrapT = THREE.RepeatWrapping;
  roughnessMap.wrapS = roughnessMap.wrapT = THREE.RepeatWrapping;
  displacementMap.wrapS = displacementMap.wrapT = THREE.RepeatWrapping;

  // Set texture tiling (repeat the texture to cover larger areas)
  diffuseMap.repeat.set(10, 10); // Adjust these numbers as needed
  normalMap.repeat.set(10, 10);
  roughnessMap.repeat.set(10, 10);
  displacementMap.repeat.set(10, 10);

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} name="Ground">
      <planeGeometry args={[100, 100]} />
      <meshStandardMaterial
        map={diffuseMap}           // Diffuse/albedo map
        normalMap={normalMap}       // Normal map
        roughnessMap={roughnessMap} // Roughness map (optional)
        displacementMap={displacementMap} // Displacement map
        displacementScale={0.1}     // Adjust displacement strength
      />
    </mesh>
  );
};


interface DinosaurProps {
  isMouseOver: boolean;
}

const Dinosaur = React.forwardRef<THREE.Group, DinosaurProps>(({ isMouseOver }, ref) => {
  const dinoRef = useRef<THREE.Group>(null!);
  const { scene, animations } = useGLTF('/models/blue_velociraptor_rig.glb');

  const { camera, mouse, scene: currentScene } = useThree();
  const targetPosition = useRef<THREE.Vector3 | null>(null);
  const raycaster = useRef(new THREE.Raycaster());
  
  // Animation mixer and action
  const mixerRef = useRef<THREE.AnimationMixer | null>(null);
  const [walkAction, setWalkAction] = useState<THREE.AnimationAction | null>(null);
  const [idleAction, setIdleAction] = useState<THREE.AnimationAction | null>(null);
  React.useImperativeHandle(ref, () => dinoRef.current);



  useEffect(() => {
    if (animations && animations.length > 0 && dinoRef.current) {
      mixerRef.current = new THREE.AnimationMixer(dinoRef.current);
      
      // Filter out position tracks (translation) to prevent the T-Rex from moving forward
      animations.forEach((clip) => {
        clip.tracks = clip.tracks.filter(track => !track.name.endsWith('.position'));
      });

      // Set up walk and idle actions
      const walk = mixerRef.current.clipAction(animations[19]); // Running with mouth open
      //const walk = mixerRef.current.clipAction(animations[22]); // Walking fast
      const idle = mixerRef.current.clipAction(animations[12]); // Idle
      walk.play();
      setWalkAction(walk);
      setIdleAction(idle);
    }
    return () => {
      if (mixerRef.current) mixerRef.current.stopAllAction();
    };
  }, [animations]);

  // Update animation frame
  useFrame((state, delta) => {
    if (mixerRef.current) mixerRef.current.update(delta);
  
    if (dinoRef.current) {
      if (isMouseOver) {
        raycaster.current.setFromCamera(mouse, camera);
        const intersects = raycaster.current.intersectObjects(currentScene.children, true);
        const groundIntersect = intersects.find(
          (intersect) => intersect.object.name === 'Ground'
        );
        if (groundIntersect) {
          targetPosition.current = groundIntersect.point;
        } else {
          targetPosition.current = null;
        }
      } else {
        targetPosition.current = null;
      }
  
      if (targetPosition.current) {
        const currentPosition = dinoRef.current.position;
        const distance = currentPosition.distanceTo(targetPosition.current);
  
        // Get the direction to the target and normalize
        const direction = targetPosition.current.clone().sub(currentPosition).normalize();
  
        // Define a constant movement speed per second, delta is the frame time in seconds
        const movementSpeed = 10; // 3 units per second
        const step = direction.multiplyScalar(movementSpeed * delta);
  
        if (distance > 0.5) { // Dino is far from the target
          dinoRef.current.position.add(step);
          dinoRef.current.position.y = 0;
  
          // Rotate dino towards the target
          const angle = Math.atan2(direction.x, direction.z);
          dinoRef.current.rotation.y = angle;
  
          // Play walk animation if it's not already playing
          if (walkAction && idleAction && !walkAction.isRunning()) {
            walkAction.setEffectiveTimeScale(4.5).reset().fadeIn(0.5).play(); // Ensure smooth fade-in transition for walking
            idleAction.fadeOut(0.1); // Smoothly transition out of idle
          }
        } else {
          // Play idle animation if it's not already playing
          if (walkAction && idleAction && !idleAction.isRunning()) {
            idleAction.setEffectiveTimeScale(1.5).reset().fadeIn(0.5).play(); // Ensure smooth fade-in transition for idle
            walkAction.fadeOut(0.1); // Smoothly transition out of walking
          }
        }
      }
    }
  });
  


  return <primitive ref={dinoRef} object={scene} scale={0.025} position={[0, 0, 0]} />;
});



  const Indoraptor: React.FC = () => {
    const trexRef = useRef<THREE.Group>(null!);
    const { scene, animations } = useGLTF('/models/indoraptor_rig.glb');
    const mixerRef = useRef<THREE.AnimationMixer | null>(null);
    const [walkAction, setWalkAction] = useState<THREE.AnimationAction | null>(null);
  
    useEffect(() => {
      if (animations && animations.length > 0 && trexRef.current) {
        mixerRef.current = new THREE.AnimationMixer(trexRef.current);
  
        // Use animation 19 for the T-Rex
        const walk = mixerRef.current.clipAction(animations[24]); // T-Rex walk/run animation //24 is still, 71 is roar
        walk.play();
        setWalkAction(walk);
      }
      return () => {
        if (mixerRef.current) mixerRef.current.stopAllAction();
      };
    }, [animations]);
  
    // Update animation frame
    useFrame((state, delta) => {
      if (mixerRef.current) mixerRef.current.update(delta);
    });
  
    return <primitive ref={trexRef} object={scene} scale={1.8} position={[-4, 0, 0]} />;
  };
  

export default DinoPen;
function lerpAngle(a: number, b: number, t: number): number {
  const shortestAngle = ((((b - a) % (2 * Math.PI)) + (3 * Math.PI)) % (2 * Math.PI)) - Math.PI;
  return a + shortestAngle * t;
}

