import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Rect, Circle } from 'react-konva';
import { Slider, Box, Typography, FormControlLabel, Switch } from '@mui/material';

interface Shape {
  x: number;
  y: number;
  vx: number; // Velocity in the x direction
  vy: number; // Velocity in the y direction
  size: number;
  color: string;
  type: 'circle' | 'rect';
}

const AnimationPage: React.FC = () => {
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [launchAngle, setLaunchAngle] = useState(45);
  const [launchPower, setLaunchPower] = useState(100);
  const [shapeSize, setShapeSize] = useState(50);
  const [gravity, setGravity] = useState(0.2); // Adjustable gravity strength
  const [speedMultiplier, setSpeedMultiplier] = useState(0.5); // Adjustable speed
  const [timingInterval, setTimingInterval] = useState(1); // Timing for new shape generation
  const [moveBase, setMoveBase] = useState(false); // Toggle for moving base position

  const baseXRef = useRef(50); // Use a ref to keep track of the base position
  const [baseDirection, setBaseDirection] = useState(1); // 1 for right, -1 for left
  const launchAngleRef = useRef(launchAngle); // Ref to keep track of launch angle
  const launchPowerRef = useRef(launchPower); // Ref to keep track of launch power
  const shapeSizeRef = useRef(shapeSize); // Ref to keep track of shape size
  const gravityRef = useRef(gravity); // Ref to keep track of gravity
  const speedMultiplierRef = useRef(speedMultiplier); // Ref to keep track of speed multiplier

  const stageRef = useRef<any>(null);
  const intervalRef = useRef<number | null>(null);
  const launchIntervalRef = useRef<number | null>(null);

  // Update refs whenever sliders change
  useEffect(() => {
    launchAngleRef.current = launchAngle;
  }, [launchAngle]);

  useEffect(() => {
    launchPowerRef.current = launchPower;
  }, [launchPower]);

  useEffect(() => {
    shapeSizeRef.current = shapeSize;
  }, [shapeSize]);

  useEffect(() => {
    gravityRef.current = gravity;
  }, [gravity]);

  useEffect(() => {
    speedMultiplierRef.current = speedMultiplier;
  }, [speedMultiplier]);

  const launchShape = () => {
    // Convert launch angle to radians
    const angleRad = (launchAngleRef.current * Math.PI) / 180;

    // Calculate initial velocities based on power and angle
    const vx = Math.cos(angleRad) * launchPowerRef.current * speedMultiplierRef.current;
    const vy = -Math.sin(angleRad) * launchPowerRef.current * speedMultiplierRef.current; // Negative because y is downward

    // Create a new shape
    const newShape: Shape = {
      x: baseXRef.current, // Start from the current base position
      y: 1080 - shapeSizeRef.current,
      vx,
      vy,
      size: shapeSizeRef.current,
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      type: Math.random() > 0.5 ? 'circle' : 'rect',
    };

    setShapes(prevShapes => [...prevShapes, newShape]);
  };

  useEffect(() => {
    const animateShapes = () => {
      setShapes(prevShapes =>
        prevShapes
          .map(shape => {
            // Update position
            const newX = shape.x + shape.vx;
            const newY = shape.y + shape.vy;

            // Apply gravity to vertical velocity
            const newVy = shape.vy + gravityRef.current * speedMultiplierRef.current;

            // Return updated shape or remove it if out of bounds
            return newX > 1920 || newY > 1080
              ? null
              : { ...shape, x: newX, y: newY, vy: newVy };
          })
          .filter(shape => shape !== null) as Shape[]
      );

      // Smoothly update base position if moveBase is enabled
      if (moveBase) {
        if (baseXRef.current <= 50) setBaseDirection(1); // Move right
        if (baseXRef.current >= 1870) setBaseDirection(-1); // Move left
        baseXRef.current += baseDirection * 2; // Adjust speed of base movement here
      }
    };

    // Set up a consistent update loop for animation
    intervalRef.current = window.setInterval(animateShapes, 16); // Approximately 60 FPS

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [moveBase, baseDirection]); // Removed dependencies that cause unnecessary re-renders

  useEffect(() => {
    // Interval to generate new shapes based on the timing slider
    if (launchIntervalRef.current) {
      clearInterval(launchIntervalRef.current);
    }
    launchIntervalRef.current = window.setInterval(() => {
      launchShape(); // Use the current baseX for launching new shapes
    }, timingInterval * 1000); // Convert seconds to milliseconds

    return () => {
      if (launchIntervalRef.current) {
        clearInterval(launchIntervalRef.current);
      }
    };
  }, [timingInterval]); // Only depends on timingInterval now

  return (
    <Box display="flex" height="100vh">
      {/* Controls Panel */}
      <Box width="20%" padding="16px" bgcolor="#f5f5f5">
        <h3>Controls</h3>

        {/* Launch Angle Slider */}
        <Typography gutterBottom>Launch Angle</Typography>
        <Slider
          value={launchAngle}
          onChange={(e, newValue) => setLaunchAngle(newValue as number)}
          min={5}
          max={90}
          step={1}
          valueLabelDisplay="auto"
          aria-labelledby="launch-angle"
          marks
        />

        {/* Launch Power Slider */}
        <Typography gutterBottom>Launch Power</Typography>
        <Slider
          value={launchPower}
          onChange={(e, newValue) => setLaunchPower(newValue as number)}
          min={50}
          max={200}  // 10X the previous max
          step={10}
          valueLabelDisplay="auto"
          aria-labelledby="launch-power"
          marks
        />

        {/* Shape Size Slider */}
        <Typography gutterBottom>Shape Size</Typography>
        <Slider
          value={shapeSize}
          onChange={(e, newValue) => setShapeSize(newValue as number)}
          min={20}
          max={200}
          step={5}
          valueLabelDisplay="auto"
          aria-labelledby="shape-size"
          marks
        />

        {/* Gravity Slider */}
        <Typography gutterBottom>Gravity</Typography>
        <Slider
          value={gravity}
          onChange={(e, newValue) => setGravity(newValue as number)}
          min={0.1}
          max={1.0}
          step={0.1}
          valueLabelDisplay="auto"
          aria-labelledby="gravity"
          marks
        />

        {/* Speed Multiplier Slider */}
        <Typography gutterBottom>Speed Multiplier</Typography>
        <Slider
          value={speedMultiplier}
          onChange={(e, newValue) => setSpeedMultiplier(newValue as number)}
          min={0.1}
          max={1.0}
          step={0.1}
          valueLabelDisplay="auto"
          aria-labelledby="speed-multiplier"
          marks
        />

        {/* Timing Interval Slider */}
        <Typography gutterBottom>Timing Interval (seconds)</Typography>
        <Slider
          value={timingInterval}
          onChange={(e, newValue) => setTimingInterval(newValue as number)}
          min={0.1}
          max={3}
          step={0.1}
          valueLabelDisplay="auto"
          aria-labelledby="timing-interval"
          marks
        />

        {/* Base Movement Toggle */}
        <FormControlLabel
          control={
            <Switch
              checked={moveBase}
              onChange={(e) => setMoveBase(e.target.checked)}
              name="moveBase"
              color="primary"
            />
          }
          label="Toggle Base Movement"
        />
      </Box>

      {/* Canvas for Animation */}
      <Box width="80%" height="100%" ref={stageRef}>
        <Stage width={1920} height={1080}>
          <Layer>
            {shapes.map((shape, index) =>
              shape.type === 'rect' ? (
                <Rect
                  key={index}
                  x={shape.x}
                  y={shape.y}
                  width={shape.size}
                  height={shape.size}
                  fill={shape.color}
                />
              ) : (
                <Circle
                  key={index}
                  x={shape.x}
                  y={shape.y}
                  radius={shape.size / 2}
                  fill={shape.color}
                />
              )
            )}
          </Layer>
        </Stage>
      </Box>
    </Box>
  );
};

export default AnimationPage;
