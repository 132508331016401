// App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Make sure this is imported
import GuessTheNumber from './pages/GuessTheNumber';
import Methods from './pages/methods'; // Adjust the import path according to your file structure
import Coinz from './pages/coinz'; // Adjust the import path according to your file structure
import LoginForm from './pages/loginform';
import SignupForm from './pages/signupform';
import Tweety from './pages/tweety';
import HomePage from './pages/homepage';
import { useAuth } from './AuthContext';
import { AuthProvider } from './AuthContext';
import Header from './components/Header'; // Adjust the import path as needed
import ProtectedRoute from './ProtectedRoute';
import UpdateRow from './components/UpdateRow'; // Make sure this import is correct
import { MainGameApp } from './pages/maingameapp';
import MarkdownEditor from "./pages/markdowneditor";
import AnimationPage from './pages/AnimationPage';
import AnimationVert from './pages/AnimationVert';
import MainGamePage from './pages/MainGamePage';
import IslandView from './components/IslandView';
import RegionView from './components/RegionView';
import NotFound from './components/NotFound';
import InventoryPage from './components/InventoryPage';

// In any React component
import { PROD_or_DEV } from './config';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#795548', // Brown color for an archaeological theme
    },
    secondary: {
      main: '#d7ccc8', // Light brown
    },
    background: {
      default: '#f5f5f5', // Light grey background
    },
  },
});

console.log(PROD_or_DEV); // Use it as needed
//<Route path="/coinz" element={<ProtectedRoute><Coinz /></ProtectedRoute>} />

function App() {
  return (
    <AuthProvider> 
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header /> {/* This will appear on every page */}
          <div className="centered-content"> {/* Adjusted with proper padding */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/methods" element={<ProtectedRoute requiredPermissions={['methods']}><Methods /></ProtectedRoute>} />
            <Route path="/coinz" element={<ProtectedRoute requiredPermissions={['coins']}><Coinz /></ProtectedRoute>} />
            <Route path="/tweety" element={<ProtectedRoute requiredPermissions={['tweety']}><Tweety /></ProtectedRoute>} />
            <Route path="/markdowneditor" element={<ProtectedRoute requiredPermissions={['editor']}><MarkdownEditor /></ProtectedRoute>} />
            <Route path="/animationpage" element={<ProtectedRoute requiredPermissions={['animation']}><AnimationPage /></ProtectedRoute>} />
            <Route path="/animationvert" element={<ProtectedRoute requiredPermissions={['animation']}><AnimationVert /></ProtectedRoute>} />
            <Route path="/loginform" element={<LoginForm />} />
            <Route path="/signupform" element={<SignupForm />} />
            <Route path="/maingameapp" element={<ProtectedRoute requiredPermissions={['game']}><MainGameApp /></ProtectedRoute>} />
            <Route path="/guessthenumber" element={<ProtectedRoute requiredPermissions={['game']}><GuessTheNumber /></ProtectedRoute>} />
            <Route path="/update-row" element={<ProtectedRoute requiredPermissions={['update']}><UpdateRow /></ProtectedRoute>} />
            <Route path="/maingame" element={<ProtectedRoute requiredPermissions={['game']}><MainGamePage /></ProtectedRoute>} />
            <Route path="/island/:id" element={<ProtectedRoute requiredPermissions={['game']}><IslandView /></ProtectedRoute>} />
            <Route path="/region/:id" element={<ProtectedRoute requiredPermissions={['game']}><RegionView /></ProtectedRoute>} />
            <Route path="/inventory" element={<ProtectedRoute requiredPermissions={['game']}><InventoryPage /></ProtectedRoute>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
