// StorageView.tsx
import React from 'react';
import { Box, Card, CardMedia } from '@mui/material';

interface Fossil {
  id: number;
  name: string;
  image: string;
}

interface StorageViewProps {
  fossils: Fossil[];
  storageCapacity: number;
}

const StorageView: React.FC<StorageViewProps> = ({ fossils, storageCapacity }) => {
  const totalSlots = storageCapacity;
  const filledSlots = fossils.length;
  const emptySlots = totalSlots - filledSlots;

  const placeholders = Array(emptySlots).fill(null);

  // Combine fossils and placeholders into a single array
  const storageItems = [...fossils, ...placeholders];

  return (
    <Box
      sx={{
        height: '33.33%', // Bottom 1/3 of the screen
        padding: 2,
        overflowX: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)', // 3 rows
          gridAutoFlow: 'column', // Fill columns first
          gap: 1,
          overflowX: 'auto',
        }}
      >
        {storageItems.map((item, index) => (
          <Card
            key={item ? item.id : `empty-${index}`}
            sx={{
              width: 100,
              height: 100,
              backgroundColor: item ? undefined : '#f0f0f0',
              border: item ? undefined : '1px dashed #ccc',
            }}
          >
            {item && (
              <CardMedia
                component="img"
                image={item.image}
                alt={item.name}
                sx={{ width: '100%', height: '100%' }}
              />
            )}
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default StorageView;
