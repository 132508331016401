// RegionView.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Archaeologist {
  id: number;
  name: string;
  level: number;
}

const RegionView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [regionName, setRegionName] = useState('');
  const [deployedArchaeologists, setDeployedArchaeologists] = useState<Archaeologist[]>([]);
  const [inventory, setInventory] = useState<Archaeologist[]>([]);
  const [selectedArchaeologistId, setSelectedArchaeologistId] = useState<number | null>(null);

  useEffect(() => {
    fetch(`http://localhost:5000/api/region/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setRegionName(data.name);
        setDeployedArchaeologists(data.archaeologists);
      })
      .catch((error) => console.error('Error fetching region data:', error));

    fetch('http://localhost:5000/api/user/user1/inventory')
      .then((response) => response.json())
      .then((data) => setInventory(data))
      .catch((error) => console.error('Error fetching inventory:', error));
  }, [id]);

  const handleDeploy = () => {
    if (selectedArchaeologistId) {
      fetch(`http://localhost:5000/api/region/${id}/deploy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: 'user1',
          archaeologist_id: selectedArchaeologistId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          alert(data.message);
          // Update state
          setInventory((prev) => prev.filter((a) => a.id !== selectedArchaeologistId));
          const deployedArchaeologist = inventory.find((a) => a.id === selectedArchaeologistId);
          if (deployedArchaeologist) {
            setDeployedArchaeologists((prev) => [...prev, deployedArchaeologist]);
          }
          setSelectedArchaeologistId(null);
        })
        .catch((error) => console.error('Error deploying archaeologist:', error));
    }
  };

  return (
    <div>
      <h2>{regionName}</h2>
      <h3>Deployed Archaeologists</h3>
      <ul>
        {deployedArchaeologists.map((a) => (
          <li key={a.id}>
            {a.name} (Level {a.level})
          </li>
        ))}
      </ul>
      <h3>Deploy Archaeologist</h3>
      <select
        value={selectedArchaeologistId || ''}
        onChange={(e) => setSelectedArchaeologistId(Number(e.target.value))}
      >
        <option value="" disabled>
          Select Archaeologist
        </option>
        {inventory.map((a) => (
          <option key={a.id} value={a.id}>
            {a.name} (Level {a.level})
          </option>
        ))}
      </select>
      <button onClick={handleDeploy} disabled={!selectedArchaeologistId}>
        Deploy
      </button>
    </div>
  );
};

export default RegionView;
