import React, { useState } from 'react';
import axios from 'axios';

const TABLE_CONFIG: { [key: string]: string[] } = {
  TargetAssets: ['AssetName', 'AssetType', 'AmountHeld', 'AssetPrice','AnchorPrice', 'HookPrice', 'Value', 'TargetPrice', 'ApiLink'],
  CoinHistory: ['Coin', 'Ticker', 'Timestamp', 'Price', 'AssetName_fk'],
  NFTHistory: ['CollectionName', 'CollectionSlug', 'Chain', 'Timestamp', 'FloorPrice', 'OfferPrice', 'AssetName_fk'],
  Palaeontologist: ['id', 'name', 'image', 'storage_capacity', 'speed', 'handling', 'skill', 'luck'],
  Dino: ['id', 'name', 'rarity', 'image', 'model', 'min_dna', 'max_dna', 'max_success', 'additional_requirements'],
  Discovery: ['id', 'name', 'dino_id', 'dna', 'delicacy', 'length', 'weight'],
  Island: ['id', 'name', 'fossil_count', 'exhausted_pc'],
  Region: ['id', 'island_id', 'name', 'fossil_count', 'exhausted_pc'],
  DiscoveryRegion: ['id'],
  UserTeam: ['id', 'user_id', 'palaeontologist_id', 'region_id', 'deployed'],
  UserTeamDiscovery: ['id', 'user_team_id', 'discovery_id', 'timestamp'],
  User: ['id', 'password_hash', 'permset', 'username', 'wallet_address'],
  // Add other tables and their columns as needed
};

const DBRecordAdder = () => {
  const [selectedTable, setSelectedTable] = useState<keyof typeof TABLE_CONFIG | ''>('');
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedTable) {
      axios.post(`/api/tables/${selectedTable}/add`, formData)
        .then(() => {
          alert('Record added successfully');
          setFormData({}); // Reset form data
        })
        .catch(error => {
          console.error('Error adding record:', error);
          alert('Failed to add record');
        });
    }
  };

  return (
    <div>
      <h2>Add Record</h2>
      <select onChange={(e) => {
        setSelectedTable(e.target.value as keyof typeof TABLE_CONFIG);
        setFormData({}); // Clear form data when table changes
      }} value={selectedTable}>
        <option value="">Select a Table</option>
        {Object.keys(TABLE_CONFIG).map(tableName => (
          <option key={tableName} value={tableName}>{tableName}</option>
        ))}
      </select>

      {selectedTable && TABLE_CONFIG[selectedTable] && (
        <form onSubmit={handleSubmit}>
          {TABLE_CONFIG[selectedTable].map((columnName) => (
            <div key={columnName}>
              <label>{columnName}:</label>
              <input
                type="text" // Adjust input type based on data type
                name={columnName}
                value={formData[columnName] || ''}
                onChange={handleFormChange}
              />
            </div>
          ))}
          <button type="submit">Add Record</button>
        </form>
      )}
    </div>
  );
};

export default DBRecordAdder;
