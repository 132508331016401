// ArchaeologistDetail.tsx
import React from 'react';
import { Box, Typography, CardMedia, Grid } from '@mui/material';

interface Archaeologist {
  id: number;
  name: string;
  photo: string;
  currentAssignment: string;
  storageCapacity: number;
  speed: number;
  handling: number;
  skill: number;
  luck: number;
  latestFind: string;
  countInWallet: number;
  owned: boolean;
}

interface ArchaeologistDetailProps {
  archaeologist: Archaeologist;
  onClose: () => void;
}

const ArchaeologistDetail: React.FC<ArchaeologistDetailProps> = ({ archaeologist, onClose }) => {
  return (
    <Box
      sx={{
        height: '66.66%', // Top 2/3 of the screen
        padding: 2,
        overflow: 'auto',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CardMedia
            component="img"
            image={archaeologist.photo}
            alt={archaeologist.name}
            sx={{ width: '100%', borderRadius: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h4" gutterBottom>
            {archaeologist.name}
          </Typography>
          <Typography variant="body1">
            <strong>Current Assignment:</strong> {archaeologist.currentAssignment}
          </Typography>
          <Typography variant="body1">
            <strong>Storage Capacity:</strong> {archaeologist.storageCapacity}
          </Typography>
          <Typography variant="body1">
            <strong>Speed:</strong> {archaeologist.speed}
          </Typography>
          <Typography variant="body1">
            <strong>Handling:</strong> {archaeologist.handling}
          </Typography>
          <Typography variant="body1">
            <strong>Skill:</strong> {archaeologist.skill}
          </Typography>
          <Typography variant="body1">
            <strong>Luck:</strong> {archaeologist.luck}
          </Typography>
          <Typography variant="body1">
            <strong>Latest Find:</strong> {archaeologist.latestFind}
          </Typography>
          <Typography variant="body1">
            <strong>Count In Wallet:</strong> {archaeologist.countInWallet}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArchaeologistDetail;
