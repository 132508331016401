// MapComponent.tsx
import React from 'react';
import IslandComponent from '../components/IslandComponent';

interface Region {
  id: number;
  name: string;
  x: number;
  y: number;
  size: number;
}

interface Island {
  id: number;
  name: string;
  x: number;
  y: number;
  size: number;
  regions: Region[]; // Add regions property to Island
}

interface MapComponentProps {
  islands: Island[]; // Accept islands prop with regions
  onIslandClick: (island: Island) => void; // Prop to handle island clicks
}

const MapComponent: React.FC<MapComponentProps> = ({ islands, onIslandClick }) => {
  return (
    <div className="map-container">
      <svg width="800" height="600">
        {islands.map((island) => (
          <IslandComponent key={island.id} island={island} onClick={() => onIslandClick(island)} />
        ))}
      </svg>
    </div>
  );
};

export default MapComponent;
