import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MarkdownEditor: React.FC = () => {
  const [markdown, setMarkdown] = useState<string>('');
  const [filename, setFilename] = useState<string>('new_file.md');
  const [fileList, setFileList] = useState<string[]>([]);  // Ensure fileList is initialized as an empty array
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [saveModal, setSaveModal] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<string | null>(null); // Current file name being edited
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false); // State for Snackbar visibility

  useEffect(() => {
    fetchFileList();
  }, []);

  // Fetch the list of markdown files
  const fetchFileList = async () => {
    try {
      const response = await axios.get('/markdown/list');
      setFileList(response.data.files || []);  // Ensure fileList is set to an empty array if response is undefined
    } catch (error) {
      console.error('Failed to fetch file list', error);
    }
  };

  // Open modal to save the file
  const handleSave = () => {
    if (currentFile) {
      setFilename(currentFile); // Autofill with current file's name
    } else {
      setFilename('new_file.md'); // Default for new files
    }
    setSaveModal(true);
  };

  // Save file function
  const saveFile = async () => {
    try {
      await axios.post('/markdown/save', { filename, content: markdown });
      setCurrentFile(filename); // Update current file name
      setSnackbarOpen(true); // Show Snackbar when file is saved
      setSaveModal(false);
      fetchFileList(); // Refresh file list after saving
    } catch (error) {
      console.error('Error saving file', error);
    }
  };

  // Open modal to load a file
  const handleOpen = () => setOpenModal(true);

  // Load file function
  const loadFile = async (selectedFilename: string) => {
    try {
      const response = await axios.get('/markdown/open', { params: { filename: selectedFilename } });
      setMarkdown(response.data.content);
      setCurrentFile(selectedFilename); // Update current file name
      setOpenModal(false);
    } catch (error) {
      console.error('Error loading file', error);
    }
  };

  // Delete file function
  const handleDelete = async () => {
    if (currentFile) {
      try {
        await axios.delete('/markdown/delete', { params: { filename: currentFile } });
        setSnackbarOpen(true); // Show Snackbar when file is deleted
        setMarkdown(''); // Clear editor
        setCurrentFile(null); // Clear current file
        fetchFileList(); // Refresh file list after deleting
      } catch (error) {
        console.error('Error deleting file', error);
      }
    }
  };

  // Close Snackbar
  const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        padding: '20px 0',
        boxSizing: 'border-box',
      }}
    >
      {/* Display Current Filename at the Top */}
      <h2>{currentFile ? `Editing: ${currentFile}` : 'No file selected'}</h2>

      {/* Editor and Preview Container */}
      <div
        style={{
          display: 'flex',
          gap: '20px',
          marginBottom: '10px',
          width: '90vw',
          maxWidth: '1600px',
          justifyContent: 'center',
        }}
      >
        {/* Raw Markdown Editor */}
        <textarea
          style={{
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            height: '75vh',
            width: '45vw',
            resize: 'none',
          }}
          value={markdown}
          onChange={(e) => setMarkdown(e.target.value)}
          placeholder="Enter Markdown text here..."
        />

        {/* Rendered Markdown Preview */}
        <div
          style={{
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            height: '75vh',
            width: '45vw',
            overflowY: 'auto',
            textAlign: 'left',
          }}
        >
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
      </div>

      {/* Action Buttons */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Button variant="contained" onClick={() => { setMarkdown(''); setCurrentFile(null); }}>New</Button>
        <Button variant="contained" onClick={handleSave}>Save</Button>
        <Button variant="contained" onClick={handleDelete}>Delete</Button>
        <Button variant="contained" onClick={handleOpen}>Open</Button>
      </div>

      {/* Save Modal */}
      <Modal open={saveModal} onClose={() => setSaveModal(false)}>
        <Box sx={modalStyle}>
          <h2>Save File</h2>
          <TextField
            label="Filename"
            variant="outlined"
            fullWidth
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
          />
          <Button variant="contained" onClick={saveFile} style={{ marginTop: '10px' }}>Save</Button>
        </Box>
      </Modal>

      {/* Open Modal */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={modalStyle}>
          <h2>Open File</h2>
          <List>
            {fileList && fileList.map((file) => (  // Add check to ensure fileList is not undefined
              <ListItem key={file}>
                <ListItemButton onClick={() => loadFile(file)}>{file}</ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

      {/* Snackbar for Success Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}  // Set auto-close duration to 300ms
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          File saved successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MarkdownEditor;
