// IslandView.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RegionComponent from './RegionComponent';

interface Region {
  id: number;
  name: string;
  exhaustion: number;
  x: number;
  y: number;
  size: number;
}

const IslandView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [regions, setRegions] = useState<Region[]>([]);
  const [islandName, setIslandName] = useState('');

  useEffect(() => {
    // Fetch island details including regions
    fetch(`http://localhost:5000/api/island/${id}`)
      .then(response => response.json())
      .then(data => {
        setIslandName(data.name);
        setRegions(data.regions);
      })
      .catch(error => console.error('Error fetching island data:', error));
  }, [id]);

  return (
    <div className="island-view">
      <h2>{islandName}</h2>
      <svg width="800" height="600">
        {regions.map(region => (
          <RegionComponent key={region.id} region={region} />
        ))}
      </svg>
    </div>
  );
};

export default IslandView;
