// src/pages/LoginForm.tsx
import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  // Get the route the user attempted to access before being redirected to login
  const from = (location.state as { from: Location })?.from?.pathname || '/';

  const handleLogin = async () => {
    try {
      await login(username, password);
      console.log('Login successful');
      // Redirect to the intended route after successful login
      navigate(from, { replace: true });
    } catch (error) {
      setError('Login failed');
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <div>{error}</div>}
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      /><br />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      /><br />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default LoginForm;
