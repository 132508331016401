import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Rect, Circle } from 'react-konva';
import { Slider, Box, Typography, FormControlLabel, Switch, Button } from '@mui/material';

interface Shape {
  x: number;
  y: number;
  vx: number; // Velocity in the x direction
  vy: number; // Velocity in the y direction
  size: number;
  color: string;
  type: 'circle' | 'rect';
}

const AnimationPage: React.FC = () => {
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [launchAngle, setLaunchAngle] = useState(90);
  const [launchPower, setLaunchPower] = useState(100);
  const [shapeSize, setShapeSize] = useState(50);
  const [gravity, setGravity] = useState(0.2); // Adjustable gravity strength
  const [speedMultiplier, setSpeedMultiplier] = useState(0.1); // Adjustable speed
  const [timingInterval, setTimingInterval] = useState(0.1); // Timing for new shape generation
  const [cubedInterval, setCubedInterval] = useState(0.5); // New cubed interval slider
  const [moveBase, setMoveBase] = useState(true); // Toggle for moving base position
  const [isRecording, setIsRecording] = useState(false); // Recording state

  const baseXRef = useRef(50); // Start from the bottom-left corner, moving horizontally
  const [baseDirection, setBaseDirection] = useState(1); // 1 for right, -1 for left
  const launchAngleRef = useRef(launchAngle); // Ref to keep track of launch angle
  const launchPowerRef = useRef(launchPower); // Ref to keep track of launch power
  const shapeSizeRef = useRef(shapeSize); // Ref to keep track of shape size
  const gravityRef = useRef(gravity); // Ref to keep track of gravity
  const speedMultiplierRef = useRef(speedMultiplier); // Ref to keep track of speed multiplier

  const stageRef = useRef<any>(null);
  const intervalRef = useRef<number | null>(null);
  const launchIntervalRef = useRef<number | null>(null);
  const cubedIntervalRef = useRef<number | null>(null); // Ref for cubed interval timer
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);

  // Global Timer Variable
  const [masterInterval, setMasterInterval] = useState(0); // Acts as the global "game loop" counter

  // Update refs whenever sliders change
  useEffect(() => {
    launchAngleRef.current = launchAngle;
  }, [launchAngle]);

  useEffect(() => {
    launchPowerRef.current = launchPower;
  }, [launchPower]);

  useEffect(() => {
    shapeSizeRef.current = shapeSize;
  }, [shapeSize]);

  useEffect(() => {
    gravityRef.current = gravity;
  }, [gravity]);

  useEffect(() => {
    speedMultiplierRef.current = speedMultiplier;
  }, [speedMultiplier]);

  // Function to generate a color based on HSL for the rainbow effect
  const getRainbowColor = (interval: number) => {
    const hue = interval % 360; // Cycle through 0 to 360
    return `hsl(${hue}, 100%, 50%)`; // Full saturation and 50% lightness for vivid colors
  };

  const launchShape = () => {
    // Convert launch angle to radians
    const angleRad = (launchAngleRef.current * Math.PI) / 180;

    // Calculate initial velocities based on power and angle
    const vx = Math.cos(angleRad) * launchPowerRef.current * speedMultiplierRef.current;
    const vy = -Math.sin(angleRad) * launchPowerRef.current * speedMultiplierRef.current; // Negative because y is downward

    const generateColor = () => {
      if (colorMode === 'Rainbow') {
        return getRainbowColor(masterInterval); // Rainbow color based on MASTERINTERVAL
      } else if (colorMode === 'Water') {
        const greenShade = Math.floor(Math.random() * 56) + 20;
        const blueShade = Math.floor(Math.random() * 106) + 150; // Random shades of blue
        return `rgb(0, ${greenShade}, ${blueShade})`;
      } else if (colorMode === 'Ice') {
        const greenShade = Math.floor(Math.random() * 50) + 210;
        const blueShade = Math.floor(Math.random() * 50) + 210; // Random shades of blue
        return `rgb(50, ${greenShade}, ${blueShade})`;
      } else if (colorMode === 'Lime') {
        const greenShade = Math.floor(Math.random() * 106) + 150; // Random shades of blue
        const blueShade = Math.floor(Math.random() * 106) + 40; // Random shades of blue
        return `rgb(0, ${greenShade}, ${blueShade})`;
      } else if (colorMode === 'Barbie') {
        const redShade = Math.floor(Math.random() * 106) + 150; // Random shades of blue
        const blueShade = Math.floor(Math.random() * 106) + 100; // Random shades of blue
        return `rgb(${redShade}, 0, ${blueShade})`;
      }
      return '#FFFFFF'; // Default to white if no mode is selected (fallback)
    };

    // Create a new shape
    const newShape: Shape = {
      x: baseXRef.current, // Start from the current base horizontal position
      y: 1920 - shapeSizeRef.current, // Start near the bottom of the canvas
      vx,
      vy,
      size: shapeSizeRef.current,
      //color: getRainbowColor(masterInterval),
      color: generateColor(),  // Get color based on MASTERINTERVAL
      type: Math.random() < 1.0 ? 'circle' : 'rect', // Randomly decide between circle or rectangle
    };

    setShapes(prevShapes => [...prevShapes, newShape]);
  };

  const centerLaunchPosition = () => {
    const stageWidth = 1080; // Assuming the canvas width is 1080
    const shapeSize = shapeSizeRef.current; // Get the current shape size
    baseXRef.current = (stageWidth - shapeSize) / 2; // Center based on the current shape size
  };

  const [colorMode, setColorMode] = useState('Rainbow'); // State to track the selected color mode

  

  useEffect(() => {
    const animateShapes = () => {
      setShapes(prevShapes =>
        prevShapes
          .map(shape => {
            // Update position
            const newX = shape.x + shape.vx;
            const newY = shape.y + shape.vy;

            // Apply gravity to vertical velocity
            const newVy = shape.vy + gravityRef.current * speedMultiplierRef.current;

            // Return updated shape or remove it if out of bounds
            return newX > 1080 || newY > 1920
              ? null
              : { ...shape, x: newX, y: newY, vy: newVy };
          })
          .filter(shape => shape !== null) as Shape[]
      );

      // Smoothly update base position if moveBase is enabled
      if (moveBase) {
        if (baseXRef.current <= 50) setBaseDirection(1); // Move right
        if (baseXRef.current >= 1030) setBaseDirection(-1); // Move left
        baseXRef.current += baseDirection * 2; // Adjust speed of base movement here
      }
    };

    // Set up a consistent update loop for animation
    intervalRef.current = window.setInterval(animateShapes, 16); // Approximately 60 FPS

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [moveBase, baseDirection]);

  useEffect(() => {
    // Interval to generate new shapes based on the timing slider
    if (launchIntervalRef.current) {
      clearInterval(launchIntervalRef.current);
    }
    launchIntervalRef.current = window.setInterval(() => {
      setMasterInterval(prev => prev + 2); // Increment MASTERINTERVAL
      launchShape(); // Use the current baseX for launching new shapes
    }, timingInterval * 1000); // Convert seconds to milliseconds

    return () => {
      if (launchIntervalRef.current) {
        clearInterval(launchIntervalRef.current);
      }
    };
  }, [timingInterval, masterInterval]);

  const [isCyclingAngle, setIsCyclingAngle] = useState(false); // State to keep track of angle cycling

  const toggleCycleAngle = () => {
    if (isCyclingAngle) {
      // If already cycling, stop it
      if (cubedIntervalRef.current) {
        clearInterval(cubedIntervalRef.current);
      }
      setIsCyclingAngle(false);
    } else {
      // Start cycling
      let increment = 1; // Variable to control the direction of the angle
  
      cubedIntervalRef.current = window.setInterval(() => {
        setLaunchAngle(prevAngle => {
          let newAngle = prevAngle + increment;
  
          // Change direction at the bounds
          if (newAngle >= 100 || newAngle <= 80) {
            increment *= -1; // Reverse direction
          }
  
          return newAngle;
        });
      }, cubedInterval * 1000); // Convert seconds to milliseconds
  
      setIsCyclingAngle(true);
    }
  };
  
  // Start Recording
  const handleStartRecording = () => {
    if (stageRef.current) {
      const canvas = stageRef.current.content.children[0]; // Get the actual HTML canvas element
      const stream = canvas.captureStream(60); // Capture 60 FPS
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp9' });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'animation.webm';
        a.click();
        URL.revokeObjectURL(url);
        recordedChunksRef.current = [];
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  // Stop Recording
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <Box display="flex" height="100vh">
      {/* Controls Panel */}
      {/* Controls Panel */}
<Box width="20%" padding="16px" bgcolor="#f5f5f5">
  <h3>Controls</h3>

  {/* Launch Angle Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Launch Angle</Typography>
    <Slider
      value={launchAngle}
      onChange={(e, newValue) => setLaunchAngle(newValue as number)}
      min={80}
      max={100}
      step={1}
      valueLabelDisplay="auto"
      aria-labelledby="launch-angle"
      marks
    />
  </Box>

  {/* Launch Power Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Launch Power</Typography>
    <Slider
      value={launchPower}
      onChange={(e, newValue) => setLaunchPower(newValue as number)}
      min={50}
      max={200}  // 10X the previous max
      step={10}
      valueLabelDisplay="auto"
      aria-labelledby="launch-power"
      marks
    />
  </Box>

  {/* Shape Size Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Shape Size</Typography>
    <Slider
      value={shapeSize}
      onChange={(e, newValue) => setShapeSize(newValue as number)}
      min={20}
      max={200}
      step={5}
      valueLabelDisplay="auto"
      aria-labelledby="shape-size"
      marks
    />
  </Box>

  {/* Gravity Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Gravity</Typography>
    <Slider
      value={gravity}
      onChange={(e, newValue) => setGravity(newValue as number)}
      min={0.1}
      max={1.0}
      step={0.1}
      valueLabelDisplay="auto"
      aria-labelledby="gravity"
      marks
    />
  </Box>

  {/* Speed Multiplier Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Speed Multiplier</Typography>
    <Slider
      value={speedMultiplier}
      onChange={(e, newValue) => setSpeedMultiplier(newValue as number)}
      min={0.1}
      max={1.0}
      step={0.1}
      valueLabelDisplay="auto"
      aria-labelledby="speed-multiplier"
      marks
    />
  </Box>

  {/* Timing Interval Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Timing Interval (seconds)</Typography>
    <Slider
      value={timingInterval}
      onChange={(e, newValue) => setTimingInterval(newValue as number)}
      min={0.01}
      max={0.25}
      step={0.01}
      valueLabelDisplay="auto"
      aria-labelledby="timing-interval"
      marks
    />
  </Box>

  {/* Cubed Interval Slider */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Cubed Interval (seconds)</Typography>
    <Slider
      value={cubedInterval}
      onChange={(e, newValue) => setCubedInterval(newValue as number)}
      min={0.1}
      max={2.0}
      step={0.1}
      valueLabelDisplay="auto"
      aria-labelledby="cubed-interval"
      marks
    />
  </Box>

  {/* Base Movement Toggle */}
  <Box marginBottom="20px">
    <FormControlLabel
      control={
        <Switch
          checked={moveBase}
          onChange={(e) => setMoveBase(e.target.checked)}
          name="moveBase"
          color="primary"
        />
      }
      label="Toggle Base Movement"
    />
  </Box>

  {/* Cycle Angle Button */}
  <Box marginBottom="20px">
    <Button
      variant="contained"
      color={isCyclingAngle ? "secondary" : "primary"}
      onClick={toggleCycleAngle}
    >
      {isCyclingAngle ? "Stop Cycle Angle" : "Start Cycle Angle"}
    </Button>
  </Box>

  {/* Center Launch Position Button */}
  <Box marginBottom="20px">
    <Button
      variant="contained"
      color="primary"
      onClick={centerLaunchPosition}
    >
      Center Launch Position
    </Button>
  </Box>

  {/* Color Mode Dropdown */}
  <Box marginBottom="20px">
    <Typography gutterBottom>Color Mode</Typography>
    <select
      value={colorMode}
      onChange={(e) => setColorMode(e.target.value)}
      style={{ padding: '8px', fontSize: '16px', width: '100%' }}
    >
      <option value="Rainbow">Rainbow</option>
      <option value="Water">Water</option>
      <option value="Ice">Ice</option>
      <option value="Lime">Lime</option>
      <option value="Barbie">Barbie</option>
    </select>
  </Box>

  {/* Recording Buttons */}
  <Box marginBottom="10px">
    <Button
      variant="contained"
      color="primary"
      onClick={handleStartRecording}
      disabled={isRecording}
    >
      Start Recording
    </Button>
  </Box>
  <Box marginBottom="20px">
    <Button
      variant="contained"
      color="secondary"
      onClick={handleStopRecording}
      disabled={!isRecording}
    >
      Stop Recording
    </Button>
  </Box>
</Box>


      {/* Canvas for Animation */}
      <Box width="80%" height="100%" ref={stageRef}>
        <Stage width={1080} height={1920} ref={stageRef}>
          <Layer>
            {/* Background Color */}
            <Rect x={0} y={0} width={1080} height={1920} fill="#000000" />

            {/* Shapes */}
            {shapes.map((shape, index) =>
              shape.type === 'rect' ? (
                <Rect
                  key={index}
                  x={shape.x}
                  y={shape.y}
                  width={shape.size}
                  height={shape.size}
                  fill={shape.color}
                />
              ) : (
                <Circle
                  key={index}
                  x={shape.x}
                  y={shape.y}
                  radius={shape.size / 2}
                  fill={shape.color}
                />
              )
            )}
          </Layer>
        </Stage>
      </Box>
    </Box>
  );
};

export default AnimationPage;
