// RegionComponent.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface RegionProps {
  region: {
    id: number;
    name: string;
    exhaustion: number;
    x: number;
    y: number;
    size: number;
  };
}

const RegionComponent: React.FC<RegionProps> = ({ region }) => {
  const handleClick = () => {
    window.location.href = `/region/${region.id}`;
  };

  return (
    <motion.g
      onClick={handleClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer' }}
    >
      <motion.circle
        cx={region.x}
        cy={region.y}
        r={region.size}
        fill="#FFA07A"
        stroke="#8B0000"
        strokeWidth="1"
        animate={{ r: [region.size, region.size + 2, region.size] }}
        transition={{ duration: 1.5, loop: Infinity }}
      />
      <text
        x={region.x}
        y={region.y - region.size - 5}
        textAnchor="middle"
        fill="#000"
        fontSize="12"
      >
        {region.name} ({region.exhaustion}%)
      </text>
    </motion.g>
  );
};

export default RegionComponent;
