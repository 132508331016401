// MainGamePage.tsx
import React, { useState } from 'react';
import MainLayout from '../components/MainLayout';
import MapComponent from '../components/MapComponent';
import DinoPen from '../components/DinoPen';
import IslandComponent from '../components/IslandComponent';
import MyTeam from '../components/MyTeam';
import ArchaeologistDetail from '../components/ArchaeologistDetail';
import StorageView from '../components/StorageView';

interface Region {
  id: number;
  name: string;
  x: number;
  y: number;
  size: number;
}

interface Island {
  id: number;
  name: string;
  x: number;
  y: number;
  size: number;
  regions: Region[];
}

interface Archaeologist {
  // ... same as before
  id: number;
  name: string;
  photo: string;
  currentAssignment: string;
  storageCapacity: number;
  latestFind: string;
  owned: boolean;
  speed: number;
  handling: number;
  skill: number;
  luck: number;
  countInWallet: number;
  fossils: Fossil[];
}

interface Fossil {
  id: number;
  name: string;
  image: string;
}

const MainGamePage: React.FC = () => {
  const [currentView, setCurrentView] = useState<string>('map');
  const [selectedIsland, setSelectedIsland] = useState<Island | null>(null);
  const [selectedArchaeologist, setSelectedArchaeologist] = useState<Archaeologist | null>(null);

  const islands: Island[] = [
    // ... same as before
  ];

  const renderContent = () => {
    if (selectedIsland) {
      return <IslandComponent island={selectedIsland} onClick={() => setSelectedIsland(null)} />;
    }

    if (selectedArchaeologist) {
      return (
        <>
          <ArchaeologistDetail
            archaeologist={selectedArchaeologist}
            onClose={() => setSelectedArchaeologist(null)}
          />
          <StorageView
            fossils={selectedArchaeologist.fossils}
            storageCapacity={selectedArchaeologist.storageCapacity}
          />
        </>
      );
    }

    switch (currentView) {
      case 'map':
        return <MapComponent islands={islands} onIslandClick={setSelectedIsland} />;
      case 'dinopen':
        return <DinoPen />;
      case 'myteam':
        return <MyTeam onSelectArchaeologist={setSelectedArchaeologist} />;
      default:
        return <MapComponent islands={islands} onIslandClick={setSelectedIsland} />;
    }
  };

  return (
    <MainLayout setCurrentView={setCurrentView}>
      {renderContent()}
    </MainLayout>
  );
};

export default MainGamePage;
