import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const DBEditor = () => {
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [records, setRecords] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedTable) {
      setLoading(true);
      axios.get(`/api/tables/${selectedTable}`).then(response => {
        setRecords(response.data);
        setLoading(false);
      }).catch(error => {
        console.error("Error fetching table records:", error);
        setLoading(false);
      });
    }
  }, [selectedTable]);

  const handleRowSelect = (record: any) => {
    navigate('/update-row', { state: { record, selectedTable } });
  };

  const tableStyle: React.CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
  };
  
  const thTdStyle: React.CSSProperties = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  };

  if (loading) return <div>Loading...</div>;

  const columnHeaders = records.length > 0 ? Object.keys(records[0]) : [];

  return (
    <div>
      <h2>DB Editor</h2>
      <select onChange={(e) => setSelectedTable(e.target.value)} value={selectedTable}>
        <option value="">Select a Table</option>
        <option value="TargetAssets">TargetAssets</option>
        <option value="CoinHistory">CoinHistory</option>
        <option value="NFTHistory">NFTHistory</option>
        <option value="User">User</option>
        <option value="Palaeontologist">Palaeontologist</option>
        <option value="Dino">Dino</option>
        <option value="Discovery">Discovery</option>
        <option value="Island">Island</option>
        <option value="Region">Region</option>
        <option value="DiscoveryRegion">DiscoveryRegion</option>
        <option value="UserTeam">UserTeam</option>
        <option value="UserTeamDiscovery">UserTeamDiscovery</option>

      </select>

      {records.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              {columnHeaders.map(header => (
                <th key={header} style={thTdStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => (
              <tr key={index} onClick={() => handleRowSelect(record)} style={{cursor: 'pointer'}}>
                {columnHeaders.map(header => (
                  <td key={`${index}-${header}`} style={thTdStyle}>{record[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No records found or select a table to view records.</p>
      )}
    </div>
  );
};

export default DBEditor;
