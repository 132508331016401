// MyTeam.tsx
import React from 'react';
import { Grid, Card, CardContent, Typography, CardMedia, Box } from '@mui/material';

interface Archaeologist {
  id: number;
  name: string;
  photo: string;
  currentAssignment: string;
  storageCapacity: number;
  latestFind: string;
  owned: boolean;
  // Additional attributes
  speed: number;
  handling: number;
  skill: number;
  luck: number;
  countInWallet: number;
  fossils: Fossil[];
}

interface Fossil {
  id: number;
  name: string;
  image: string;
}

interface MyTeamProps {
  onSelectArchaeologist: (archaeologist: Archaeologist) => void;
}

const archaeologists: Archaeologist[] = [
  // ... (same as before, but include the additional attributes)
  {
    id: 1,
    name: 'Dr. Jones',
    photo: 'https://via.placeholder.com/150',
    currentAssignment: 'Exploring Egypt',
    storageCapacity: 50,
    latestFind: 'Ancient Amulet',
    owned: true,
    speed: 80,
    handling: 70,
    skill: 90,
    luck: 60,
    countInWallet: 2,
    fossils: [
      { id: 1, name: 'Amulet', image: 'https://via.placeholder.com/80' },
      { id: 2, name: 'Coin', image: 'https://via.placeholder.com/80' },
      { id: 3, name: 'Statue', image: 'https://via.placeholder.com/80' },
      { id: 4, name: 'Ring', image: 'https://via.placeholder.com/80' },
      { id: 5, name: 'Scroll', image: 'https://via.placeholder.com/80' },
      { id: 6, name: 'Mask', image: 'https://via.placeholder.com/80' },
    ],
  },
  // ... other archaeologists
];

const MyTeam: React.FC<MyTeamProps> = ({ onSelectArchaeologist }) => {
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {archaeologists.map((archaeologist) => (
          <Grid item xs={12} sm={6} md={4} key={archaeologist.id}>
            <Card
              sx={{ position: 'relative', cursor: archaeologist.owned ? 'pointer' : 'default' }}
              onClick={() => archaeologist.owned && onSelectArchaeologist(archaeologist)}
            >
              <CardMedia
                component="img"
                height="140"
                image={archaeologist.photo}
                alt={archaeologist.name}
                sx={{
                  opacity: archaeologist.owned ? 1 : 0.5,
                }}
              />
              {!archaeologist.owned && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h6">Not Owned</Typography>
                </Box>
              )}
              <CardContent
                sx={{
                  opacity: archaeologist.owned ? 1 : 0.5,
                }}
              >
                <Typography variant="h6" component="div">
                  {archaeologist.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Current Assignment: {archaeologist.currentAssignment}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Storage Capacity: {archaeologist.storageCapacity}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Latest Find: {archaeologist.latestFind}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyTeam;
