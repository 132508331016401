// InventoryPage.tsx
import React, { useEffect, useState } from 'react';

interface Archaeologist {
  id: number;
  name: string;
  level: number;
}

const InventoryPage: React.FC = () => {
  const [archaeologists, setArchaeologists] = useState<Archaeologist[]>([]);

  useEffect(() => {
    fetch('http://localhost:5000/api/user/user1/inventory')
      .then((response) => response.json())
      .then((data) => setArchaeologists(data))
      .catch((error) => console.error('Error fetching inventory:', error));
  }, []);

  return (
    <div>
      <h2>Your Archaeologists</h2>
      <ul>
        {archaeologists.map((a) => (
          <li key={a.id}>
            {a.name} (Level {a.level})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryPage;
