// IslandComponent.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface Region {
  id: number;
  name: string;
  x: number;
  y: number;
  size: number;
}

interface IslandProps {
  island: {
    id: number;
    name: string;
    x: number;
    y: number;
    size: number;
    regions: Region[];
  };
  onClick: () => void;
}

const IslandComponent: React.FC<IslandProps> = ({ island, onClick }) => {
  const handleRegionClick = (region: Region) => {
    console.log(`Clicked on region: ${region.name}`);
  };

  return (
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      preserveAspectRatio="xMidYMid meet"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <motion.path
        d={`M ${island.x},${island.y - island.size} C ${island.x + island.size},${island.y - island.size}, ${island.x + island.size},${island.y + island.size}, ${island.x},${island.y + island.size} C ${island.x - island.size},${island.y + island.size}, ${island.x - island.size},${island.y - island.size}, ${island.x},${island.y - island.size} Z`}
        fill="#8FBC8F"
        stroke="#2E8B57"
        strokeWidth="2"
        animate={{ fill: ['#8FBC8F', '#FFD700', '#8FBC8F'] }}
        transition={{ duration: 2, loop: Infinity }}
      />
      <text
        x={island.x}
        y={island.y - island.size - 10}
        textAnchor="middle"
        fill="#000"
        fontSize="14"
        fontWeight="bold"
      >
        {island.name}
      </text>
      {island.regions.map((region) => (
        <motion.circle
          key={region.id}
          cx={region.x}
          cy={region.y}
          r={region.size}
          fill="#4682B4"
          stroke="#1E90FF"
          strokeWidth="2"
          style={{ cursor: 'pointer' }}
          onClick={() => handleRegionClick(region)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        />
      ))}
    </motion.svg>
  );
};

export default IslandComponent;
