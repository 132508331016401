// MainLayout.tsx
import React from 'react';
import { Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { FaUser, FaCogs } from 'react-icons/fa';

interface MainLayoutProps {
  children: React.ReactNode;
  setCurrentView: (view: string) => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, setCurrentView }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
      }}
    >
      {/* Content Area */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Game Content Area with Border */}
        <Box
          sx={{
            flexGrow: 1,
            margin: 0,
            border: '3px solid #ddd',
            padding: 0,
            overflow: 'auto',
            boxSizing: 'border-box',
            backgroundImage: 'url("https://www.transparenttextures.com/patterns/asfalt-dark.png")',
            backgroundSize: 'cover',
          }}
        >
          {children}
        </Box>

        {/* Right Sidebar Menu */}
        <Box
          className="right-sidebar"
          sx={{
            width: '9%',
            height: '100%',
            bgcolor: '#4CAF50',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            minWidth: '80px',
            boxSizing: 'border-box',
            borderLeft: '1px solid #ccc',
          }}
        >
          <List disablePadding>
            <ListItem component="button" onClick={() => setCurrentView('myteam')}>
              <ListItemIcon>
                <FaUser />
              </ListItemIcon>
              <ListItemText primary="My Team" />
            </ListItem>
            <ListItem component="button" onClick={() => setCurrentView('profile')}>
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem component="button" onClick={() => setCurrentView('dinopen')}>
              <ListItemIcon>
                <FaCogs />
              </ListItemIcon>
              <ListItemText primary="Dino Pen" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
