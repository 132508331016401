// Trex.tsx

import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

const Trex: React.FC = () => {
  const trexRef = useRef<THREE.Group>(null!);
  const { scene, animations } = useGLTF('/models/trex_rig.glb') as any;
  const mixerRef = useRef<THREE.AnimationMixer | null>(null);

  const [currentState, setCurrentState] = useState<'idle' | 'walking'>('idle');
  const [actionMap, setActionMap] = useState<{ [key: string]: THREE.AnimationAction }>({});
  const [targetPosition, setTargetPosition] = useState<THREE.Vector3 | null>(null);
  const idleDuration = useRef(0);
  const walkDuration = useRef(0);

  // Map boundaries
  const mapBounds = {
    minX: -50,
    maxX: 50,
    minZ: -50,
    maxZ: 50,
  };

  // State settings for movement and animation speeds
  const stateSettings: {
    [key: string]: { movementSpeed: number; animationSpeed: number; yOffset: number };
  } = {
    idleTall: { movementSpeed: 0, animationSpeed: 2, yOffset: 0.3 },
    idleLow: { movementSpeed: 0, animationSpeed: 2, yOffset: 5 },
    walk: { movementSpeed: 5, animationSpeed: 2.5, yOffset: 0 },
  };

  useEffect(() => {
    if (animations && animations.length > 0 && trexRef.current) {
      mixerRef.current = new THREE.AnimationMixer(trexRef.current);

      // Filter out position tracks to prevent unwanted movement
      animations.forEach((clip: THREE.AnimationClip) => {
        clip.tracks = clip.tracks.filter((track) => !track.name.endsWith('.position'));
      });

      // Create a map of animations
      const actions: { [key: string]: THREE.AnimationAction } = {
        idleTall: mixerRef.current.clipAction(animations[18]),
        idleLow: mixerRef.current.clipAction(animations[19]),
        walk: mixerRef.current.clipAction(animations[28]),
        stalk: mixerRef.current.clipAction(animations[29]),
        charge: mixerRef.current.clipAction(animations[13]),
        attack: mixerRef.current.clipAction(animations[21])
      };

      // Apply animation speeds
      for (const [name, action] of Object.entries(actions)) {
        const settings = stateSettings[name];
        if (settings) {
          action.setEffectiveTimeScale(settings.animationSpeed);
        }
      }

      // Play the idle animation initially
      actions.idleTall.play();
      setActionMap(actions);
    }

    return () => {
      if (mixerRef.current) mixerRef.current.stopAllAction();
    };
  }, [animations]);

  // Function to transition between animations
  const playAction = (name: string) => {
    if (actionMap[name]) {
      Object.values(actionMap).forEach((action) => {
        if (action.isRunning()) action.fadeOut(0.5);
      });

      // Adjust animation speed if needed
      const animationSpeed = stateSettings[name]?.animationSpeed || 1;
      actionMap[name].setEffectiveTimeScale(animationSpeed);

      actionMap[name].reset().fadeIn(0.5).play();

      // Adjust Y offset
      const yOffset = stateSettings[name]?.yOffset || 0;
      trexRef.current.position.y = yOffset;
    }
  };

  // Function to pick a random idle animation
  const playIdle = () => {
    const idleAnimations = ['idleTall', 'idleLow'];
    const randomIdle = idleAnimations[Math.floor(Math.random() * idleAnimations.length)];
    playAction(randomIdle);
  };

  // Function to pick a random point to walk to within the map bounds
  const pickRandomTarget = () => {
    const radius = 50;
    let x, z;

    // Keep generating random positions until one is within bounds
    let attempts = 0;
    do {
      const angle = Math.random() * Math.PI * 2;
      x = trexRef.current.position.x + Math.cos(angle) * radius;
      z = trexRef.current.position.z + Math.sin(angle) * radius;
      attempts++;
    } while (
      (x < mapBounds.minX || x > mapBounds.maxX || z < mapBounds.minZ || z > mapBounds.maxZ) &&
      attempts < 10
    );

    // If after 10 attempts we still don't have a valid position, default to the center
    if (attempts >= 10) {
      x = 0;
      z = 0;
    }

    setTargetPosition(new THREE.Vector3(x, 0, z));
  };

  // Custom lerpAngle function
  const lerpAngle = (a: number, b: number, t: number): number => {
    const shortestAngle = ((((b - a) % (2 * Math.PI)) + 3 * Math.PI) % (2 * Math.PI)) - Math.PI;
    return a + shortestAngle * t;
  };

  useFrame((state, delta) => {
    if (mixerRef.current) mixerRef.current.update(delta);

    if (!trexRef.current) return;

    const trexPosition = trexRef.current.position;

    switch (currentState) {
      case 'idle':
        idleDuration.current += delta;
        if (idleDuration.current > 2) {
          setCurrentState('walking');
          playAction('walk');
          idleDuration.current = 0;
          pickRandomTarget();
        }
        break;

      case 'walking':
        if (targetPosition && trexPosition.distanceTo(targetPosition) < 0.5) {
          setCurrentState('idle');
          playIdle();
          walkDuration.current = 0;
          setTargetPosition(null);
        } else {
          walkDuration.current += delta;
          if (walkDuration.current > 20) {
            setCurrentState('idle');
            playIdle();
            walkDuration.current = 0;
            setTargetPosition(null);
          }
        }
        break;

      default:
        break;
    }

    // Movement Logic
    if (targetPosition && currentState === 'walking') {
      const direction = targetPosition.clone().sub(trexPosition);
      const distance = direction.length();
      direction.normalize();

      const movementSpeed = stateSettings['walk']?.movementSpeed || 0;

      // Ensure T-Rex doesn't overshoot the target
      const moveStep = Math.min(movementSpeed * delta, distance);
      const step = direction.clone().multiplyScalar(moveStep);

      // Calculate the new position
      const newPosition = trexPosition.clone().add(step);

      // Clamp the new position within the map bounds
      newPosition.x = THREE.MathUtils.clamp(newPosition.x, mapBounds.minX, mapBounds.maxX);
      newPosition.z = THREE.MathUtils.clamp(newPosition.z, mapBounds.minZ, mapBounds.maxZ);

      // Update the T-Rex's position
      trexRef.current.position.set(newPosition.x, newPosition.y, newPosition.z);

      // Adjust Y position
      trexRef.current.position.y = stateSettings['walk']?.yOffset || 0;

      // Smooth rotation towards the direction
      const targetRotation = Math.atan2(direction.x, direction.z);
      const currentYRotation = trexRef.current.rotation.y;
      const rotationSpeed = delta * 4;
      trexRef.current.rotation.y = lerpAngle(currentYRotation, targetRotation, rotationSpeed);
    }
  });

  return <primitive ref={trexRef} object={scene} scale={2} position={[0, 0, 0]} />;
};

export default Trex;
